/* AudioApprovalStatus.css */

/* Style for the audio player */
.audio-player {
    width: 100%;
    max-width: 300px; /* Limit the maximum width */
    margin: 0 auto; /* Center the player */
    padding: 6px; /* Add padding */
}

/* Additional styles can be added here for further customization */
